import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ProjectHeader from "../components/project-header"
import ProjectBlurb from "../components/project-blurb"

const info = {
    duration: "2017 - 2019",
    references: [
        {
            head: "Engineering Reference",
            name: "Kevin Kim",
            title: "Project Manager",
            employer: "Jacobs Engineering",
            phone: "(425) 233 3360"
        },
        {
            head: "Client Reference",
            name: "John Vicente",
            title: "City Engineer",
            employer: "City of Kenmore",
            phone: "(425) 984 6154",
        },
    ],
}

const Page = () => {
    const data = useStaticQuery(graphql`
      query {
        header: file(relativePath: { eq: "project-header--kenmore.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mapp: file(relativePath: { eq: "map--kenmore.png" }) {
          childImageSharp {
            fluid(maxWidth: 680) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
    return (
        <Layout hideMenu={true}>
          <SEO title="West Sammamish Bridge Replacement" />
          <ProjectHeader
            client="City of Kenmore"
            project="West Sammamish Bridge Replacement"
            image={data?.header?.childImageSharp?.fluid}
          />
          <ProjectBlurb data={info} mapImage={data.mapp.childImageSharp.fluid}>
            <p>
              The project will replace the existing two-lane bridge
              with a new two-lane bridge, adding mixed-use bicycle and
              pedestrian path on the west side of the bridge. The
              project included eight partial fee acquisitions and four
              temporary construction easements from multi-family,
              commercial and recreational zoned properties.  In
              addition, it required two relocation and a complex 6(f)
              exchange with Washington Department of Fish and
              Wildlife.  RES Group NW has completed all relocations
              including a relocation plan, managed and oversaw the
              appraisal and review appraisal consultants, prepared
              documentations, cleared title and facilitated closing.
              WSDOT certification 3 was obtained and after clearing
              the necessary components to complete the 6(f) exchange,
              the WSDOT certification was converted to a 1.
            </p>
          </ProjectBlurb>
        </Layout>
    )
}

export default Page
